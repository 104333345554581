<template>
  <section class="flex flex-col gap-2">
    <label class="font-serif text-xl font-semibold" :for="`${label}-list`">{{
      label
    }}</label>
    <ul :id="`${label}-list`" class="flex flex-col gap-1">
      <slot
        v-for="item in items"
        name="item"
        :item="item"
        :search-term="searchTerm"
      >
        <SearchResultItem
          v-if="item.type === 'product'"
          :key="item.productSuggestion.product.id"
          :suggestion="item.productSuggestion.suggestion"
          :term="searchTerm"
          :to="
            $fimLocalePath(
              getProductDetailRoute(item.productSuggestion.product),
            )
          "
          :image-url="
            showImages
              ? getImageFromList(
                  item.productSuggestion.product.images,
                  ProductImageType.BUST,
                  'front',
                )?.hash
              : ''
          "
          @mousedown="$emit('click:result', item)"
        >
        </SearchResultItem>
        <SearchResultItem
          v-if="item.type === 'brandOrCategory'"
          :key="item.brandOrCategorySuggestion.category.id"
          :suggestion="item.brandOrCategorySuggestion.suggestion"
          :term="searchTerm"
          :to="item.brandOrCategorySuggestion.category.path"
          @mousedown="$emit('click:result', item)"
        >
          {{ item.brandOrCategorySuggestion.category.name }}
        </SearchResultItem>
      </slot>
    </ul>
  </section>
</template>

<script setup lang="ts">
import type { TypeaheadSuggestion } from '@scayle/storefront-nuxt'

defineProps({
  searchTerm: {
    type: String as PropType<string>,
    default: '',
  },
  label: {
    type: String as PropType<string>,
    required: true,
  },
  items: {
    type: Array as PropType<TypeaheadSuggestion[]>,
    default: () => [] as TypeaheadSuggestion[],
  },
  showImages: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
})
const { $fimLocalePath } = useNuxtApp()

const { getProductDetailRoute } = useRouteHelpers()
defineEmits<{
  (e: 'click:result', item: TypeaheadSuggestion): void
}>()
</script>
